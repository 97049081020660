import ImgMenu from '../images/ic_menu.svg';
import React, { Component } from 'react';
import axios from 'axios';
import DataTable from './data-table';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";
import PropTypes from 'prop-types';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
} from 'react-switch-lang';
import en from '../en.json';
import th from '../th.json';
import es from '../es.json';

setTranslations({ en, th, es });
setDefaultLanguage('en');

// If you want to remember selected language
setLanguageCookie();

//export default class Menu extends Component {
class Menu extends React.Component {

    state = {
        show: true,
    }

    toggle = () => this.setState((currentState) => ({show: !currentState.show}));

        
    handleSetLanguage = (key) => () => {
        setLanguage(key);
    };

    constructor(props) {
        super(props);
        this.state = { usuariosCollection: [] };
    }

    componentDidMount() {
        var url = "https://aguitech.casidios.com/panel/ws_notas_react.php";
        var params = {
            data1: 'holamundo',
        }
        //axios.get('http://aguitech.casidios.com/panel/ws_notas_react.php')
        axios.post(url, params)
            .then(res => {
                //console.log()
                //this.setState({ usersCollection: res.data });
                console.log(res.data.resultado);
                this.setState({ usuariosCollection: res.data.resultado });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    dataTable() {
        return this.state.usuariosCollection.map((data, i) => {
            return <DataTable obj={data} key={i} />;
        });
    }

    render() {
        const { t } = this.props;
        return (
            
                <div className="">
                    <div className="contenedor_menu_btn">
                        <img src={ImgMenu} style={{height: "25px"}}  onClick={this.toggle} />
                    </div>
                    {this.state.show && <div className="container_menu" onClick={this.toggle}>
                        <div>
                            <Link to="/">{t('menu.inicio')}</Link>
                        </div>
                        <div>
                            <Link to="/services">{t('menu.servicios')}</Link>
                        </div>
                        <div>
                            <Link to="/clients">{t('menu.clientes')}</Link>
                        </div>
                        <div>
                            <Link to="/contact">{t('menu.contacto')}</Link>
                        </div>
                        
                        <div>
                            <img src="https://aguitech.com/images/utiles/flags/jpn.png" style={{height: "40px"}} onClick={this.handleSetLanguage('th')}/>
                            <img src="https://aguitech.com/images/utiles/flags/usa.png" style={{height: "40px"}} onClick={this.handleSetLanguage('en')} />
                            <img src="https://aguitech.com/images/utiles/flags/mex.png" style={{height: "40px"}} onClick={this.handleSetLanguage('es')} />
                        </div>
                    </div>
                    }

                    
                    

                    
                    
                    
                </div>
            
        )
    }
}
/*
SHOW / HIDE
<img src={ImgMenu} style={{height: "25px"}}  onClick={this.toggle} />
<img src="https://aguitech.com/blue/images/logo_aguitech/Aguitech_logo.png" style={{height: "40px"}} />
                    

TERNARIOS

{this.state.show ? 'show' : 'hide'}

{this.state.show && <div>Hi there</div>}



ENLACES

<div>
                            <Link to="/about">About</Link>
                        </div>
                        <div>
                            <Link to="/topics">Topics</Link>
                        </div>
                        <div className="nav-item active">
                            <Link className="nav-link" to={"/create-user"}>Create User</Link>
                        </div>
                        <div className="nav-item">
                            <Link className="nav-link" to={"/users"}>Users List</Link>
                        </div>
                        <div className="nav-item">
                            <Link className="nav-link" to={"/usuarios"}>Usuarios</Link>
                        </div>
                        <div className="nav-item">
                            <Link className="nav-link" to={"/notas"}>Notas</Link>
                        </div>
                        <div className="nav-item">
                            <Link className="nav-link" to={"/blog"}>Blog</Link>
                        </div>
                        <div className="nav-item">
                            <Link className="nav-link" to={"/traducciones"}>Traducciones</Link>
                        </div>
                        <div className="nav-item">
                            <Link className="nav-link" to={"/traduccionesdos"}>Traducciones dos</Link>
                        </div>
                        <div className="nav-item">
                            <Link className="nav-link" to={"/lenguaje"}>Lenguaje</Link>
                        </div>
                        <li className="nav-item">
                            <Link
                                    to={{ pathname: '/Carnamas/10'}}
                                    className="list-group-item"
                                    key={10}>
                                    Que show
                                </Link>
                        </li>



<table className="table table-striped table-dark">
                        <thead className="thead-dark">
                            <tr>
                                <td>ID</td>
                                <td>Name</td>
                                <td>Email</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataTable()}
                        </tbody>
                    </table>
{this.dataTable()}
{this.dataTable()}


{t('home.title')}
                    {t('home.title', null, 'th')}
                    {t('hello', { name: 'World' })}
                    {t('fallback')}
            
                    <button type="button" onClick={this.handleSetLanguage('th')}>
                    Switch language TH
                    </button>
                    <button type="button" onClick={this.handleSetLanguage('en')}>
                    Switch language EN
                    </button>
                    <button type="button" onClick={this.handleSetLanguage('es')}>
                    Switch language ES
                    </button>
*/
Menu.propTypes = {
    t: PropTypes.func.isRequired,
  };
   
export default translate(Menu);