import React, { Component } from 'react';
import axios from 'axios';
import DataTable from './data-table';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { usuariosCollection: [] };
    }

    componentDidMount() {
        var url = "https://aguitech.casidios.com/panel/ws_notas_react.php";
        var params = {
            data1: 'holamundo',
        }
        //axios.get('http://aguitech.casidios.com/panel/ws_notas_react.php')
        axios.post(url, params)
            .then(res => {
                //console.log()
                //this.setState({ usersCollection: res.data });
                console.log(res.data.resultado);
                this.setState({ usuariosCollection: res.data.resultado });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    dataTable() {
        return this.state.usuariosCollection.map((data, i) => {
            return <DataTable obj={data} key={i} />;
        });
    }

    render() {
        return (
            
                <div className="container">
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">Abouta</Link>
                    </li>
                    <li>
                        <Link to="/topics">Topics</Link>
                    </li>
                    <li className="nav-item active">
                        <Link className="nav-link" to={"/create-user"}>Create User</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={"/users"}>Users List</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={"/usuarios"}>Usuarios</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={"/notas"}>Notas</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={"/blog"}>Blog</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={"/traducciones"}>Traducciones</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={"/traduccionesdos"}>Traducciones dos</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={"/lenguaje"}>Lenguaje</Link>
                    </li>
                    </ul>
                    <table className="table table-striped table-dark">
                        <thead className="thead-dark">
                            <tr>
                                <td>ID</td>
                                <td>Name</td>
                                <td>Email</td>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </table>
                </div>
            
        )
    }
}
/*
{this.dataTable()}
{this.dataTable()}
*/