import React, { Component } from 'react';
import axios from 'axios';
import DataTable from './data-table';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";
import PropTypes from 'prop-types';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
} from 'react-switch-lang';
import en from '../en.json';
import th from '../th.json';
import es from '../es.json';

setTranslations({ en, th, es });
setDefaultLanguage('en');

// If you want to remember selected language
setLanguageCookie();

//export default class Menu extends Component {
class Contacto extends React.Component {

    handleSetLanguage = (key) => () => {
        setLanguage(key);
    };

    constructor(props) {
        super(props);
        this.state = { usuariosCollection: [] };
    }

    componentDidMount() {
        var url = "https://aguitech.casidios.com/panel/ws_notas_react.php";
        var params = {
            data1: 'holamundo',
        }
        //axios.get('http://aguitech.casidios.com/panel/ws_notas_react.php')
        axios.post(url, params)
            .then(res => {
                //console.log()
                //this.setState({ usersCollection: res.data });
                console.log(res.data.resultado);
                this.setState({ usuariosCollection: res.data.resultado });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    dataTable() {
        return this.state.usuariosCollection.map((data, i) => {
            return <DataTable obj={data} key={i} />;
        });
    }

    render() {
        const { t } = this.props;
        return (
            
                <div>
                    <div className="contenedor_titulo_seccion">
                        {t('menu.contacto')}
                    </div>
                    <div><div style={{display: "flex", alignItems: "center", justifyContent: "center"}}><img src="https://aguitech.com/blue/images/logo_aguitech/Aguitech_logo.png" className="logo_home" />
                    </div>
                    <div style={{display:"flex", justifyContent:"center", fontSize:"24px"}}>
                    <div>{t('inicio.scrum')}</div>
                    <div> - </div>
                    <div>{t('inicio.ux')}</div>
                    <div> - </div>
                    <div>{t('inicio.design')}</div>
                    <div> - </div>
                    <div>{t('inicio.develop')}</div>
                    <div> - </div>
                    <div>{t('inicio.test')}</div>

                    </div>
                    </div>
                </div>
            
        )
    }
}
/*
{this.dataTable()}
{this.dataTable()}
*/
Contacto.propTypes = {
    t: PropTypes.func.isRequired,
  };
   
export default translate(Contacto);